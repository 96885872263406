import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">What makes Coinsclone the Best <span className="bluecolor">ICO Development Company?</span></h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ico/what-makes-ico-development-company.webp"
                alt="best ico development company"
                placeholder='none'
                className='mt-3'
                width={550}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Coinsclone, the popular and the best ICO development company will be your trusted partner. We make your crypto dreams take flight with our ICO development services. Our technical team always welcomes your business ideas and provides relevant guidance for creating a seamless ICO website.
              </p>
              <p className="pharagraph">Our developer team offers you a helping hand in creating ICO tokens, a wallet, a payment gateway, and a perfect ICO landing page. Our ICO development services never go out of style and buckle up for your crypto business success.
              </p>
              <ul>
                <li>250+ successful ICO projects</li>
                <li>Expert team of Developers</li>
                <li>Affordable Cost for Startups</li>
                <li>Customizable Solutions</li>
                <li>24*7 Technical Support</li>
                <li>Advanced Level Tech Stack</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose