import React from "react"

class DevService extends React.Component {

  render() {
    return (

      <section className="dapps ico pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">ICO <span className="bluecolor">Development Services</span></h2>
            <p className='text-center'>We offer numerous top-rated ICO development services to get the limelight in the cryptocurrency world.</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3 class="head3">
                            <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/ico-consulting-services/">ICO Consulting</a>
                            </h3>
                            <p className='pharagraph'>Our team empowers every newbie startup with tremendous ICO consulting services. We assure to provide a myriad of guidance throughout the ICO development journey for hassle-free token distribution.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/crypto-token-development/">ICO Token Development</a></h3>
                            <p className='pharagraph'>We execute your vision in a prolific way for developing a flawless crypto token within your project budget. We develop all kinds of token types with exponential features, functionalities, and potentiality. Our developer team will be always ready to redesign your crypto token as per your business requirements.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/cryptocurrency-wallet-development-company/">Crypto Wallet Development</a></h3>
                            <p className='pharagraph'>Our team specializes in developing seamless and secure crypto wallets. Cryptocurrency wallets are important to safeguard, store, and access the newly created crypto tokens. The crypto wallet we develop stands ahead in the crypto world with its infrastructure.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/cryptocurrency-payment-gateway-development/">Payment Gateway Integration</a></h3>
                            <p className='pharagraph'>Making every transaction on the ICO website safe and secure, we provide a crypto payment gateway for your crypto users. If users face any technical issue or anything else, they will contact the admin flawlessly.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/smart-contract-development-company/">Smart Contract Development</a></h3>
                            <p className='pharagraph'>Our team integrates exemplary and potential smart contracts to automate the functions. Also, we implement smart contract functions in crypto token creation to provide your users with seamless transactions.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/ico-whitepaper/">ICO White Paper Development</a></h3>
                            <p className='pharagraph'>We create engaging ICO white papers that communicate your project’s vision, goals, and technical aspects. We focus on attracting potential investors by outlining your value proposition, tokenomics, and roadmap effectively.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/ico-token-sale-platform-development/">Token Sale Platform Development</a></h3>
                            <p className='pharagraph'>Our team specialises in developing robust ICO token sale platforms that ensure secure and seamless fundraising for crypto projects. Our solutions stand out in the market, providing the infrastructure for trust and transparency. We empower you to achieve your fundraising goals efficiently.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/contact-us/">Community Marketing Services</a></h3>
                            <p className='pharagraph'>Enhance engagement with our tailored community marketing services. We specialize in building, nurturing, and growing loyal communities that drive brand awareness and customer loyalty through strategic communication and support.</p>
                        </div>
                        <div className='fea-card'>
                            <h3 class="head3">
                              <a target="_blank" rel="noreferrer" href="https://www.coinsclone.com/ico-dashboard-script/">ICO Dashboard Designing</a></h3>
                            <p className='pharagraph'>Our team designs user-friendly ICO dashboards that provide real-time insights into your project’s performance. With a focus on security and functionality, these dashboards enhance navigation and deliver key metrics to keep investors informed and engaged.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevService
