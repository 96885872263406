import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class DevProcess extends React.Component {

  render() {
    return (
      <section className="process-of ico pt-100 mb-0">
          <div className="gray-bg">
            <div className="container">     
              <h2 className="heading-h2 text-center">Our ICO <span className="bluecolor">Development Process</span>
              </h2>
              <p className="text-center">
              We focus on crafting an extraordinary ICO dashboard to catch the eyes of every investor to raise funds.
              </p>
              <div className="d-lg-flex add-one">
                <div className="square">
                  <h3>Ideation</h3>
                  <p className="pharagraph">
                  Our developer team unleashes creativity to bring out unique ideas for creating the ICO project. At the initial stage, our expert team frames the outline for wallet integration, ICO token development, and ICO website design.
                  </p>
                </div>
                <div className="square">
                  <h3>WhitePaper Creation</h3>
                  <p className="pharagraph">
                  Our technical team helps you to frame a whitepaper where Tokenomics, objectives, pre-sale & post-sale details, and ICO website launch are included. Our technical team is mastered in providing the relevant information for crypto users in a simpler form.
                  </p>
                </div>
                <div className="square">
                  <h3>Smart Contract Creation</h3>
                  <p className="pharagraph">
                  At Coinsclone, our team integrates exemplary and potential smart contracts to automate the functions. Also, we implement smart contract functions in crypto token creation to provide your users with seamless transactions.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-two">
                <div className="square" >
                  <h3>ICO Token Development</h3>
                  <p className="pharagraph">
                  We transform your innovative ideas into reality with our cryptocurrency token development. Our technical team designs the token symbol, name, total supply, features, functionalities, and token standard. We also optimize the crypto token that suits your needs.
                  </p>
                </div>
                <div className="square" >
                  <h3>ICO Dashboard Designing</h3>
                  <p className="pharagraph">
                  Our team focuses on providing a seamless dashboard because that’s how your users see you. We integrate dashboards with user-friendly features, and robust security interfaces. Our UI/UX designers and technicians make your ICO website an unbeatable one.
                  </p>
                </div>
              </div>
              <div className="d-lg-flex add-three">
                <div className="square">
                  <h3>Security Audit / Bug Fixing</h3>
                  <p className="pharagraph">
                  We perform security audits to identify vulnerabilities in your ICO platform, ensuring its safety from threats. Our expert bug-fixing services address issues promptly, allowing you to focus on fundraising while your project remains secure and reliable.
                  </p>
                </div>
                <div className="square">
                  <h3>Token Sale Platform Development</h3>
                  <p className="pharagraph">
                  Our team specializes in developing robust token sale platforms that ensure secure and seamless fundraising for crypto projects. Our solutions stand out in the market, providing the infrastructure for trust and transparency. We empower you to achieve your fundraising goals efficiently.
                  </p>
                </div>
                <div className="square" >
                  <h3>Post-ICO Marketing</h3>
                  <p className="pharagraph">
                  We implement targeted post-ICO marketing strategies to sustain community engagement and interest. We keep your investors informed and excited about your project’s progress through social media outreach and influencer partnerships.
                  </p>
                </div>
              </div>
              <div className="mt-3 text-center">
                  <ButtonComponent />
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default DevProcess
