import React from "react"

const DevModal = () => {


    return (
      <section className="fine-box tokenize modall pt-100 mb-0 pb-3">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Our White label ICO <span className="bluecolor">Development Models</span>
            </h2>
            <p className="text-center mb-2">
            Explore our customizable White Label ICO solutions that empower you to launch your token sale swiftly and effectively.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>
                  <a className="link" href="https://www.coinsclone.com/white-label-ico-platform/">White label ICO Platform</a></h3>
                <p className="pharagraph">
                Our White Label ICO platform offers a fully customizable solution, allowing you to launch your token sale quickly. With pre-built features and functionalities, you can focus on branding and marketing while we handle the technical aspects.
                </p>
              </div>
              <div className="square">
                <h3>
                  <a className="link" href="https://www.coinsclone.com/ico-script/">ICO Script</a></h3>
                <p className="pharagraph">
                Our ICO script provides a ready-to-use solution for launching your token sale, complete with essential features and functionalities. This cost-effective option allows you to save time while ensuring a professional and compliant launch.
                </p>
              </div>
              <div className="square">
                <h3>
                  <a className="link" href="https://www.coinsclone.com/ico-software-development/">ICO Software</a></h3>
                <p className="pharagraph">
                We specialise in tailored ICO software development that meets your specific project requirements. Our team creates secure, scalable, and user-friendly software solutions that facilitate smooth fundraising and enhance investor experience.
                </p>
              </div>
            </div>
          </div>
      </section>
    )
  }


export default DevModal
