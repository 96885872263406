import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const FeatureBenefits = () => {


  return (
    <section className="customs ico pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Futuristic Benefits</span> Of ICO Development For Startups
            </h2>
            <p className='text-center'>Our ICO development services unlock infinite possibilities and perks to get the spotlight among crypto users.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Immense Earning Streams</h3>
                    <p class="pharagraph">Our expert team offers the necessary guidance for integrating revenue streams into your ICO dashboard. You can get funds for your crypto project without following the tiring traditional fundraising method.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Community Engagement</h3>
                    <p class="pharagraph">At the initial stage of your ICO business, you can build your ecosystem or community. It further leads you to create and develop your cryptocurrency business to the next level. You can achieve beyond the boundaries with our development services.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Long-Term Success</h3>
                    <p class="pharagraph">Fix your business dream with an Initial coin offering as it will offer long-term success. ICOs are the potential way for a long-lasting investment as they support early adopters. Transform your crypto business to the next level with ICO development.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Global Accessibility</h3>
                    <p class="pharagraph">From anywhere you can launch your ICO website to raise funds for your crypto project. So that you can reap funds from a larger group of investors. Enter into various cryptocurrency businesses with the ICO project.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Cost-Effective Solution</h3>
                    <p class="pharagraph">With a minimum amount of investment, you can reap enough profits with the ICO development services. We offer the best-ever ICO development that suits a newbie startup’s budget and time.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Enhanced Liquidity</h3>
                    <p class="pharagraph">ICOs are the popular methods in the crypto realm as they introduce new crypto tokens. By conducting an effective ICO, you can increase the liquidity of that specific token in various exchanges.</p>
                </div>
            </div>
        </div>
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default FeatureBenefits