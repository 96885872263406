import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="benifor ico pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="bluecolor">Exponential Features</span> Of Our ICO Development
            </h2>
            <p className="text-center">
            Our developer team turns your dashboard imagination into reality with efficient and effective dashboard features.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Token Listing </h3>
                <p className="pharagraph">
                Our platform facilitates seamless token listing on multiple exchanges, ensuring maximum visibility and accessibility for your cryptocurrency. This helps attract more investors and enhances trading opportunities.
                </p>
              </div>
              <div className="square" >
                <h3>Smart Contract Audit </h3>
                <p className="pharagraph">
                We provide thorough smart contract audits to identify vulnerabilities and ensure the security and reliability of your project. This critical step builds trust among investors and safeguards against potential exploits.
                </p>
              </div>
              <div className="square" >
                <h3>Multi-currency support</h3>
                <p className="pharagraph">
                Our ICO user landing page comes with supporting multiple cryptocurrency features to grab the attention of crypto investors. We work on the hustle to provide you and your user with a hassle-free website.
                </p>
              </div>
              <div className="square" >
                <h3>Database Backup</h3>
                <p className="pharagraph">
                Restoring the transaction, withdrawal, and recent transaction data is essential for both the user and admin. Apparently, our developer team works on the back end to provide a data backup feature.
                </p>
              </div>
              <div className="square" >
                <h3>Due Diligence Report </h3>
                <p className="pharagraph">
                Our due diligence reports offer an analysis of your project, assessing its viability and compliance with regulatory standards. This report enhances investor confidence and facilitates informed decision-making.
                </p>
              </div>
              <div className="square" >
                <h3>Airdrops and Bounty Programs </h3>
                <p className="pharagraph">
                We design strategic airdrop and bounty programs to boost community engagement and increase awareness of your token. These initiatives incentivize participation, help build a loyal user base, and drive demand for your cryptocurrency.
                </p>
              </div>
              <div className="square" >
                <h3>Real-Time Performance Analytics</h3>
                <p className="pharagraph">
                Our developer team crafts the ICO admin dashboard with various analytical tools to monitor user activity. We customize the dashboard with this feature to prevent your ICO website from hacks, and irrelevant activities.
                </p>
              </div>
              <div className="square" >
                <h3>Customer Support</h3>
                <p className="pharagraph">
                To reach out to the admin easily, we offer 24/7 customer support on the ICO website. Users can solve their problems at any time without any interference. Our technicians design with utmost care to provide an easy navigating dashboard.
                </p>
              </div>
              <div className="square" >
                <h3>Legal and Regulatory Compliance</h3>
                <p className="pharagraph">
                Our team assures to offer relevant and potential tools for checking the legal norms for all geographical regions. Our high-end tools provide privacy policies, terms of services, and other regulatory details for administrators.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
